import 'focus-visible'
import 'regenerator-runtime/runtime'
import 'core-js/stable'

import { registerApplication, start } from 'single-spa'
import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'

import { getGlobalCustomProps, getI18nProps } from './utils'

const env = getCurrentEnvironment()

async function setup() {
  try {
    const globalCustomProps = getGlobalCustomProps(
      window.TT_GLOBALS.restaurantInfo,
      {
        i18n: await getI18nProps(window.TT_GLOBALS.restaurantInfo)
      }
    )

    registerApplication({
      name: 'capman-guest-spa',
      app: () => global.System.import('capman-guest-spa'),
      activeWhen: () => true,
      customProps() {
        return globalCustomProps
      }
    })

    registerApplication({
      name: 'spa-dev-tools',
      app: () => global.System.import('spa-dev-tools'),
      activeWhen: () => true,
      customProps() {
        return globalCustomProps
      }
    })

    start({ urlRerouteOnly: true })
  } catch (e) {
    if (env === ToastEnvironment.DEV || env === ToastEnvironment.PREPROD) {
      console.error(e)
    }
  }
}
setup()
