import i18next from 'i18next'
import { Intl } from '@toasttab/banquet-types'
import { checkLocale, Locale } from '@toasttab/buffet-pui-locale-utilities'
import { RestaurantInfo } from '../../types'

function getLocaleFromProps(restaurantInfo: RestaurantInfo) {
  const country = restaurantInfo?.country
  const language = restaurantInfo?.language

  return checkLocale(`${language}-${country}` as Locale)
}

function getCurrency(restaurantInfo: RestaurantInfo): Intl['currency'] {
  return restaurantInfo?.currency as Intl['currency']
}

export async function getI18nProps(restaurantInfo: RestaurantInfo) {
  const locale = getLocaleFromProps(restaurantInfo)
  const currency = getCurrency(restaurantInfo)

  return i18next
    .init({
      lng: locale,
      fallbackLng: 'en',
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false
      },
      resources: {}
    })
    .then(() => ({
      language: locale,
      currency,
      i18next
    }))
}
