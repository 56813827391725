import { Intl } from '@toasttab/banquet-types'
import { RestaurantInfo } from '../../types'

interface ExtraProps {
  i18n: Intl
}

export function getGlobalCustomProps(
  restaurantInfo: RestaurantInfo,
  extraProps: ExtraProps
) {
  const globalData = window?.__TOAST_BANQUET_INITIAL_DATA__ || {}
  const { enabledFeatureFlags } = window?.TT_GLOBALS || {}
  return {
    ...globalData,
    ...extraProps,
    restaurantInfo: {
      ...restaurantInfo
    },
    featureFlags: enabledFeatureFlags.reduce((obj, flag) => {
      obj[flag] = true
      return obj
    }, {})
  }
}
